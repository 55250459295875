@tailwind base;
@tailwind components;
@tailwind utilities;

#CSSProgress {
    /*transform: translateX(calc(((1 - (1 * var(--progress) ) ))*500px));*/
    /*tleft: calc(((1 - (1 * var(--progress) ) ))*-400px);*/
    /*height: calc((var(--progress) )*100%);*/
    /*opacity: calc(1-(var(--progress)*-1 ));*/
    left: calc((((1 * var(--progress) ) ))*-430px);

}

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

/*
* {
    background: #000 !important;
    color: #0f0 !important;
    outline: solid #f00 1px !important;
  }
*/